<template>
  <div class="divBox">
    <el-card class="box-card">
      <div class="mb20 acea-row">
        <el-button size="small" type="primary" class="mb10" @click="onAdd"
          >添加商品分组</el-button
        >
      </div>
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        ref="table"
        row-key="id"
        :key="itemkey"
        :default-expand-all="false"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column prop="sort" label="排序" min-width="50" />
        <el-table-column label="分组名称" prop="name" min-width="80">
        </el-table-column>
        <el-table-column label="分组图标" min-width="80">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                style="width: 36px; height: 36px"
                :src="scope.row.icon ? scope.row.icon : moren"
                :preview-src-list="[scope.row.icon ? scope.row.icon : moren]"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="是否显示" min-width="150">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isShow"
              :active-value="1"
              :inactive-value="0"
              active-text="显示"
              inactive-text="隐藏"
              @change="onchangeIsShow(scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="60" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="small"
              @click="onEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              icon="el-icon-delete"
              @click="handleDelete(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="500"
      :before-close="handleClose"
    >
      <avue-form
        :option="option"
        v-model="form"
        ref="form"
        :upload-error="uploadError"
        :upload-delete="uploadDelete"
        :upload-before="uploadBefore"
        :upload-after="uploadAfter"
        @submit="formSubmit"
      >
        <template slot="image">
          <FormImgUpload
            :url="form.image"
            @upload="upload"
            :limit="1"
            :maxSize="5"
            accept="image/jpeg,image/png,image/jpg"
            :multiple="true"
          />
          <div class="tipImg">尺寸推荐:96X96，格式：jpeg,png,jpg</div>
        </template>
        <template slot="img">
          <FormImgUpload
            :url="form.img"
            @upload="uploadImg"
            :limit="1"
            :maxSize="5"
            accept="image/jpeg,image/png,image/jpg"
            :multiple="true"
          />
          <div class="tipImg">尺寸推荐:510X258，格式：jpeg,png,jpg</div>
        </template>
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
          <el-button type="primary" icon="el-icon-check" @click="submit(form)"
            >确定</el-button
          >
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  goodsClassifyAdd,
  goodsClassifyList,
  goodsClassifyDel,
  goodsClassifyState,
  goodsClassifyUpdate,
  goodsClassifySort,
} from "@/api/goods/goods";
import Sortable from "sortablejs";
import FormImgUpload from "@/components/formComponents/formImgUpload";
export default {
  name: "ProductClassify",
  components: {
    FormImgUpload,
  },
  data() {
    return {
      itemkey: 1,
      moren: require("@/assets/images/bjt.png"),
      isChecked: false,
      listLoading: false,
      tableData: {
        data: [{}],
        total: 0,
      },
      tableFrom: {},
      title: "新增分组",
      dialogVisible: false,
      form: {
        image: [],
        img: [], //
        isShow: 0,
        type: 1,
      },
      option: {
        labelWidth: "120",
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "上级分组",
            prop: "parentId",
            type: "cascader",
            maxlength: 20,
            row: true,
            placeholder: "请输入",
            checkStrictly: true,
            props: { checkStrictly: true, label: "name", value: "id" },
            dicData: [],
            change: (e) => {
              if (e.value[0] == 0) {
                this.option.column[2].rules[0].required = true;
                let arr = [
                  {
                    label: "分组横幅",
                    row: true,
                    type: "text",
                    prop: "img",
                    span: 24,
                    rules: [
                      {
                        required: true,
                        message: "请上传分组横幅",
                        trigger: "blur",
                      },
                      {
                        validator: (rule, value, callback) => {
                          if (value.length === 0) {
                            callback(new Error("请上传"));
                          } else {
                            callback();
                          }
                        },
                      },
                    ],
                  },
                ];

                this.option.column = [
                  ...this.option.column.slice(0, 1),
                  ...arr,
                  ...this.option.column.slice(1, 5),
                ];
              }

              if (e.value[0] != 0 && this.option.column.length == 6) {
                this.option.column.splice(1, 1);
                this.option.column[2].rules[0].required = false;
              }
            },
            rules: [
              {
                required: true,
                message: "请选择",
                trigger: "change",
              },
            ],
          },
          {
            label: "分组名称",
            prop: "name",
            maxlength: 20,
            // showWordLimit: true,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入分组名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "分组图标",
            row: true,
            type: "text",
            prop: "image",
            span: 24,
            rules: [
              {
                required: true,
                message: "请上传分组图片",
                trigger: "blur",
              },
            ],
          },
          {
            label: "是否显示",
            prop: "isShow",
            type: "switch",
            row: true,
            dicData: [
              {
                label: "关",
                value: 0,
              },
              {
                label: "开",
                value: 1,
              },
            ],
          },
          {
            label: "排序",
            prop: "sort",
            controlsPosition: "",
            type: "number",
            rules: [
              {
                required: true,
                message: "请输入排序",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    uploadDelete() {
      // return this.$confirm(`是否确定移除？`);
    },
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadError(error, column) {
      this.$message.error(error);
      // console.log(error, column);
    },
    uploadAfter(res, done) {
      done();
      // this.$message.success('上传后的方法')'
      // console.log(res);
      // this.form.image = res.image;
      // this.$nextTick(() => {
      //   this.form.image = res.path;
      // });
    },
    formSubmit() {},
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          let that = this;

          if (this.form.id) {
            if (that.form.parentId.length == 3) {
              that.$message.error("最多新增3级");
              return;
            }
            goodsClassifyUpdate({
              ...that.form,
              icon: that.form.image.length > 0 ? that.form.image[0] : "",
              img: that.form.img.length > 0 ? that.form.img[0] : "",
              parentId: that.form.parentId[0],
            }).then((res) => {
              // 获取数据成功后的其他操作
              // console.log(res);
              if (res.code === 200) {
                this.$message.success("修改成功");
                that.getList();
                that.handleClose();
                // done();
              } else {
                that.$message.error(res.msg);
                // done();
              }
            });
          } else {
            if (that.form.parentId.length == 3) {
              that.$message.error("最多新增3级");
              return;
            }
            goodsClassifyAdd({
              ...that.form,
              icon: that.form.image.length > 0 ? that.form.image[0] : "",
              img: that.form.img.length > 0 ? that.form.img[0] : "",
              parentId: that.form.parentId[that.form.parentId.length - 1],
            }).then((res) => {
              // 获取数据成功后的其他操作
              // console.log(res);
              if (res.code === 200) {
                that.$message.success(res.msg);
                that.getList();
                that.handleClose();
                // done();
              } else {
                that.$message.error(res.msg);
                // done();
              }
            });
          }
          this.form.type = 1;
          // done()
        }
      });
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.form.image = [];
      this.form.isShow = 0;
      this.form.img = [];
      this.dialogVisible = false;
    },
    // 列表
    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.form.image = [...e];
      } else {
        this.form.image = [...this.form.image, e];
      }
    },
    //横幅上传
    uploadImg(e) {
      if (Array.isArray(e)) {
        this.form.img = [...e];
      } else {
        this.form.img = [...this.form.img, e];
      }
    },
    getList() {
      this.listLoading = true;
      goodsClassifyList(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.data;
          let arr = JSON.parse(JSON.stringify(res.data));
          arr.unshift({
            name: "顶级分组",
            id: "0",
          });
          this.option.column[0].dicData = arr;
          this.listLoading = false;
          this.itemkey = this.itemkey + 1;
          this.$nextTick(() => {
            this.rowDrop();
          });
        })
        .catch((res) => {
          this.listLoading = false;
          this.$message.error(res.message);
        });
    },
    treeToTile(treeData, childKey = "children") {
      const arr = [];
      const expanded = (data) => {
        if (data && data.length > 0) {
          data
            .filter((d) => d)
            .forEach((e) => {
              arr.push(e);
              expanded(e[childKey] || []);
            });
        }
      };
      expanded(treeData);
      return arr;
    },
    rowDrop() {
      const tbody = this.$refs.table.$el.querySelectorAll(
        ".el-table__body-wrapper > table > tbody"
      )[0];
      Sortable.create(tbody, {
        animation: 300,
        onMove: () => {
          this.activeRows = this.treeToTile(this.tableData.data); // 把树形的结构转为列表再进行拖拽
        },
        onEnd: (e) => {
          //e.oldIndex为拖动一行原来的位置，e.newIndex为拖动后新的位置
          if (e.oldIndex !== e.newIndex) {
            console.log("oldIndex", e.oldIndex);
            console.log("newIndex", e.newIndex);
            // 根据自己项目需求增添条件限制
            //从上往下移动

            const oldRow = this.activeRows[e.oldIndex]; //拖动的元素
            const upRow =
              e.oldIndex < e.newIndex
                ? this.activeRows[e.newIndex]
                : this.activeRows[e.newIndex - 1]; // 拖动位置的上个元素
            const downRow =
              e.oldIndex < e.newIndex
                ? this.activeRows[e.newIndex + 1]
                : this.activeRows[e.newIndex]; // 拖动位置的下个元素

            console.log("oldIndex", oldRow); //拖动的元素
            console.log("upRow", upRow);
            console.log("downRow", downRow);
            if (oldRow.level == 1) {
              let id = oldRow.id;
              let levelArrUp = this.activeRows
                .slice(0, e.newIndex + 1)
                .filter((ele) => {
                  return ele.level == 1;
                });
              let levelArrDown = this.activeRows
                .slice(e.newIndex + 1)
                .filter((ele) => {
                  return ele.level == 1;
                });
              let obj = {
                id: oldRow.id,
                prefixId:
                  levelArrUp.length > 1
                    ? levelArrUp[levelArrUp.length - 1].id
                    : 0,
                suffixId: levelArrDown.length > 0 ? levelArrDown[0].id : 0,
                parentId: 0,
              };
              this.tableSort(obj);
            }
            if (oldRow.level == 2) {
              if(!upRow){
                this.$message.error("二级不能拖动最前面");
                this.getList()
                return
              }
              let id = oldRow.id;
              //二级移动到一级下面第一个
              if (upRow.level == 1) {
                let level1ChildrenArr = upRow.children;
                let levelArrDown = level1ChildrenArr.filter((ele) => {
                  return ele.level == 2;
                });
                console.log("1231", levelArrDown);
                let obj = {
                  id: oldRow.id,
                  prefixId: 0,
                  suffixId: levelArrDown.length > 0 ? levelArrDown[0].id : 0,
                  parentId: upRow.id,
                };
                console.log("123obj", obj);
                this.tableSort(obj);
              }
              //拖动前面是一级默认加到第一项
              // if (upRow.level == 1) {
              //   let parentItem = upRow.children;
              //   let obj = {
              //     id: oldRow.id,
              //     prefixId: 0,
              //     suffixId:
              //       parentItem.children.length > 0
              //         ? parentItem.children[0].id
              //         : 0,
              //     parentId: parentItem.id,
              //   };
              //   this.tableSort(obj);
              //   console.log("123", obj);
              // }
              //拖动前面是二级
              if (upRow.level == 2) {
                let parentItem = {};
                this.tableData.data.map((item) => {
                  if (item.children) {
                    item.children.map((items) => {
                      if (items.id == upRow.id) {
                        parentItem = item;
                      }
                    });
                  }
                });
                let index = parentItem.children.findIndex(
                  (element) => element,
                  id === upRow.id
                );
                console.log("123---", index);

                let obj = {
                  id: oldRow.id,
                  prefixId: upRow.id,
                  suffixId:
                    parentItem.children.length > index + 1
                      ? parentItem.children[index + 1].id
                      : 0,
                  parentId: parentItem.id,
                };
                  //同级别两个元素拖拽会出现相同的情况
                if(obj.prefixId==obj.suffixId){
                  obj.suffixId=0
                }
                this.tableSort(obj);
              }
              //拖动前面是三级级
              if (upRow.level == 3) {
                let parentItem = {};
                let upItem = {};
                let downItem = {};
                this.tableData.data.map((item) => {
                  if (item.children) {
                    item.children.map((items, indexs) => {
                      if (items.children) {
                        items.children.map((itemss) => {
                          if (itemss.id == upRow.id) {
                            parentItem = item;
                            upItem = items;
                            downItem = item.children[indexs + 1]
                              ? item.children[indexs + 1]
                              : null;
                          }
                        });
                      }
                    });
                  }
                });
                let obj = {
                  id: oldRow.id,
                  prefixId: upItem.id,
                  suffixId: downItem?downItem.id:0,
                  parentId: parentItem.id,
                };
                this.tableSort(obj);
              }
            }
            //拖动的三级元素
            if (oldRow.level == 3) {
              if (upRow.level == 1) {
                this.$message.error("不能拖动到一级数据");
                this.getList();
                return;
              }
              //三级元素拖动到二级下面默认放到顶级
              if (upRow.level == 2) {
                let obj = {
                  id: oldRow.id,
                  prefixId: 0,
                  suffixId:
                    upRow.children.length > 1 ? upRow.children[0].id : 0,
                  parentId: upRow.id,
                };
                console.log("123", obj);
                this.tableSort(obj);
              }
              //三级元素拖动到三级级下面默认放到顶级
              if (upRow.level == 3) {
                let parentItem = {};
                let upItem = {};
                let downItem = {};
                this.tableData.data.map((item) => {
                  if (item.children) {
                    item.children.map((items) => {
                      if (items.children) {
                        items.children.map((itemss, indexss) => {
                          if (itemss.id == upRow.id) {
                            parentItem = items;
                            upItem = itemss;
                            downItem = items.children[indexss + 1]
                              ? items.children[indexss + 1]
                              : null;
                          }
                        });
                      }
                    });
                  }
                });
                console.log('1231',downItem) 
                let obj = {
                  id: oldRow.id,
                  prefixId: upItem.id,
                  suffixId:downItem?downItem.id:0,
                  parentId: parentItem.id,
                };
           
                this.tableSort(obj);
              }
            }

            // 请求接口排序，根据后端要求填写参数
            // let obj = {
            //   id : oldRow.id,
            //   prefixId:oldRow.level==
            // }
          }
        },
      });
    },
    tableSort(obj) {
      this.listLoading = true;
      goodsClassifySort(obj)
        .then((res) => {
          // 获取数据成功后的其他操作
          this.listLoading = false;
          this.getList();
        })
        .catch((err) => {
          this.$message.error(err.msg);
          this.listLoading = false;
        });
    },
    // 添加
    onAdd() {
      this.form.id = -null;
      this.dialogVisible = true;
    },
    // 编辑
    onEdit(row) {
      this.form = JSON.parse(JSON.stringify(row));
      console.log("1231", this.form);
      this.form.image = this.form.icon ? [this.form.icon] : [];
      this.form.img = this.form.img ? [this.form.img] : [];
      this.form.parentId = [this.form.parentId];
      console.log("1231", this.form);
      this.dialogVisible = true;
    },
    // 删除
    handleDelete(id) {
      let that = this;
      this.$confirm(`删除这条数据`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        callback: (action) => {
          if (action == "confirm") {
            goodsClassifyDel(id)
              .then((res) => {
                // 获取数据成功后的其他操作
                if (res.code === 200) {
                  that.getList();
                  that.$message.success(res.msg);
                } else {
                  that.$message.error(res.msg);
                }
              })
              .catch((err) => {
                this.$message.error(err.msg);
              });
          }
        },
      });
    },
    onchangeIsShow(row) {
      goodsClassifyState({ id: row.id, isShow: row.isShow })
        .then(({ msg }) => {
          this.getList();
          this.$message.success(msg);
        })
        .catch(({ msg }) => {
          this.$message.error(msg);
          this.getList();
        });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
